.partner-wrapper {
  display: flex;
  flex-direction: column;
  background: $white;
  padding: 1rem 1rem 0 1rem;

  &.partner-wrapper--article {
    padding: 0;
  }
}

.partner-element__wrapper {
  position: relative;
  background-color: $white;
  padding: $outline-width;
  margin-bottom: 1rem;
}

.partner-element__inner-wrapper {
  position: relative;
  .partner-element__wrapper--gold & {
    outline: $outline-width solid $gold;
  }
  .partner-element__wrapper--silver & {
    outline: $outline-width solid $silver;
  }
  .partner-element__wrapper--bronze & {
    outline: $outline-width solid $bronze;
  }
}

.partner-element__head {
  position: absolute;
  top: 0;
  left: 0;
  padding: 0.25rem 2rem;
  font-size: 1rem;
  text-align: center;

  @include breakpoint(small only) {
    position: relative;
    top: -1px;
    left: 0;
    width: fit-content;
    box-sizing: border-box;
  }

  .partner-element__wrapper--gold & {
    background-color: $gold;
  }
  .partner-element__wrapper--silver & {
    background-color: $silver;
  }
  .partner-element__wrapper--bronze & {
    background-color: $bronze;
  }
}

.partner-element__logos {
  display: flex;
  flex-wrap: wrap;
  padding: 1rem 0 1rem 1rem;
  width: 100%;
  height: 100%;

  @include breakpoint(medium) {
    padding: 5rem 2rem 3rem 4rem;
  }
}

.partner-element__logo-container {
  display: flex;
  flex: 0 1 45%;
  margin-right: 0.25rem;
  padding: 1rem;
  align-items: center;
  align-self: center;
  justify-content: center;

  @include breakpoint(medium) {
    margin-right: 1rem;
  }

  @include breakpoint(large) {
    flex: 0 1 30%;
    height: 8rem;
    margin-bottom: 2.5rem;

    .article-detail & {
      flex: 0 1 31%;
    }
  }
}

.partner-element__logo {
  display: block;
  max-width: 100%;
  height: auto;
}
