.articles__article {
  display: block;
  background: $lightgray;
  padding-bottom: 1rem;
  margin-bottom: 2rem;

  .articles__journal-image {
    float: right;
    max-width: 5rem;
    max-height: 3rem;
  }

  .articles__image {
    display: block;

    @include breakpoint(small only) {
      max-width: none;
      width: calc(100% + 50px);
      margin-left: -25px;
      margin-right: -25px;
    }

    @include breakpoint(medium) {
      width: 100%;
    }
  }

  .articles__pubdate,
  .articles__title,
  .articles__excerpt,
  .articles__more {
    @include xy-gutters($gutter-position: left right);
    display: block;
  }

  .articles__pubdate {
    padding-top: 0.5em;
  }

  .articles__title {
    margin-top: 0;
    margin-bottom: 0.25em;
  }

  .articles__excerpt {
    line-height: 1.3;
  }
}
