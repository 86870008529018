// Nav configs
@use "sass:math";

$sticky-header-height: rem-calc(55);
$sticky-header-height-large: 6.5rem;
$sticky-header-height-small: 4.5rem;

$icon-height: 1.5rem;
$icon-width: 2rem;
$icon-margin: 1rem;
$stroke-height: 0.25rem;
$stroke-width: 100%;

$nav-toggle-transition: 0.25s ease-in-out;

// Offset for anchors
a.anchor {
  display: block;
  position: relative;
  visibility: hidden;
  top: -6rem;

  @include breakpoint(large) {
    top: -5.5rem;
  }
}

// Navigation
// ==========

.header {
  .page--no-moodboard &,
  &.page--no-moodboard {
    margin-bottom: $general-margin * 2;
    @include breakpoint(medium) {
      margin-bottom: $general-margin * 3;
    }
  }

  .page--region & {
    margin-bottom: 0;
  }
  .article-detail & {
    margin-bottom: $general-margin;
  }
}

.sticky-header {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  background-color: $white;
  box-shadow: 0 4px 8px 1px rgba(0, 0, 0, 0.06);
  z-index: 10000;
  transition: transform 0.3s;
  transform: translateY(-100%);

  &.active,
  .page--no-moodboard & {
    transform: translateY(0);
  }

  .page--region & {
    background: none;
    box-shadow: none;
  }

  .overlay--sticky & {
    height: 100vh;
    background: none;
  }
}

.sticky-header--region {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: $primary-color;
  mix-blend-mode: multiply;

  .page--region & {
    //display: block;
  }
}

.sticky-header__navigation {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem 0;

  .overlay--sticky & {
    height: 85vh;
    align-items: normal;

    @include breakpoint(medium) {
      height: 92vh;
    }
  }
}

.sticky-header__logo {
  font-size: $body-font-size-large;
  font-weight: $bold;
  color: $red;
  z-index: 100001;

  .page--region & {
    color: $white;
  }

  br {
    display: none;
  }

  @include breakpoint(small only) {
    font-size: rem-calc(18px);
    line-height: normal;
  }

  .overlay--sticky & {
    color: $white;

    @include breakpoint(small only) {
      br {
        display: block;
      }
    }
  }
}

.sticky-header__navigation--left {
  transition: padding 0.3s;
  .overlay--sticky & {
    padding-left: math.div($claw-width, $claw-thickness-mobile);

    @include breakpoint(medium) {
      padding-left: $claw-width + 2rem;
    }
  }
}

.sticky-header__navigation--right {
  transition: padding 0.3s;

  .overlay--sticky & {
    padding-right: math.div($claw-width, $claw-thickness-mobile);

    @include breakpoint(medium) {
      padding-right: $claw-width + 2rem;
    }
  }
}

.sticky-header__navigation {
  .moodboard__claw {
    opacity: 0;
    top: 2rem !important;

    .overlay--sticky & {
      opacity: 1;
    }
  }

  .moodboard__claw--left {
    transition: none;
    opacity: 0;
    transform: translateX(-10rem);

    .overlay--sticky & {
      transition: transform 0.3s cubic-bezier(0, 0, 0.175, 1);
      opacity: 1;
      transform: translate(0);
    }
  }

  .moodboard__claw--right {
    transition: none;
    opacity: 0;
    transform: translateX(10rem);

    .overlay--sticky & {
      transition: transform 0.3s cubic-bezier(0, 0, 0.175, 1);
      opacity: 1;
      transform: translate(0);
    }
  }
}

.navigation__meta {
  display: flex;
  flex-direction: row;
  align-items: center;
  order: 2;
  list-style: none;
  margin: 0;
  padding: 0.3rem 0.5rem 0.3rem 0;
  line-height: 2;
  color: $primary-color;
  font-size: rem-calc(18px);
  font-weight: 700;
  transition: transform 0.4s ease-in-out;
  .page--region & {
    color: $white;
  }
  @include breakpoint(large) {
    font-size: rem-calc(14px);
    font-family: $font-body;
    justify-content: flex-end;

    a {
      padding: 0 0.5rem;
    }

    .scrolled & {
      transform: translateY(10%);
    }

    .scrolled--down & {
      transform: translateY(-100%);
    }
  }

  .nav-is-open & {
    color: transparentize($white, 0.4);
  }
}

.navigation__social {
  list-style: none;
  margin: 0;

  svg {
    width: 1.5rem;
    height: 1.5rem;
    path {
      fill: $primary-color !important;
      .page--region & {
        fill: $white !important;
      }
    }
    .icon--stroke {
      stroke: $primary-color !important;
      .page--region & {
        stroke: $white !important;
      }
    }
    .icon--fill {
      fill: $primary-color !important;
      .page--region & {
        fill: $white !important;
      }
    }
  }

  .nav-is-open & {
    svg {
      width: 1.5rem;
      height: 1.5rem;
      path {
        fill: $white !important;
      }
      .icon--stroke {
        stroke: $white !important;
      }
      .icon--fill {
        fill: $white !important;
      }
    }
  }

  &.navigation__social--desktop {
    display: none;
    @include breakpoint(large) {
      display: flex;
    }
  }
  &.navigation__social--mobile {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 0 1.2rem;
    .nav-is-open & {
      display: flex;
    }
    li {
      padding: 0 0.5rem;
    }
    @include breakpoint(large) {
      display: none;
    }
  }

  .page--region & {
    @include breakpoint(small only) {
      display: none;
    }
  }
}

.navigation__meta--left {
  list-style: none;
  margin: 0;
  display: flex;
  .nav-is-open & {
    display: flex;
    flex-direction: column;
  }

  li {
    @include breakpoint(medium down) {
      padding: 0.15rem 0;
    }
  }
  .page--region & {
    pointer-events: auto;
  }
}

.navigation__container {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  opacity: 0;
  display: none;
  transition:
    transform 0.4s ease-in-out,
    opacity 200ms ease-in-out;
  transform: translateY(-20px);

  @include breakpoint(large) {
    display: flex;
    align-items: center;
    transform: translateY(0);
    opacity: 1;
    position: relative;
    height: 100%;

    .navigation__container--overlay {
      width: 100%;
    }
  }

  .nav-is-open & {
    transform: translateY(10px);
    //height: 80vh;
    opacity: 1;
    display: block;
    padding-bottom: 5rem;
    background: transparent;
  }
  @include breakpoint(medium down) {
    margin-top: 5rem;
    .navigation__container--overlay {
      display: flex;
      flex-direction: column;
      padding: 0 2vw;
      margin: 2rem;
    }
  }
}

.navigation__list {
  position: relative;
  order: 1;
  transition: transform 0.4s ease-in-out;

  .page--region & {
    pointer-events: auto;
  }
  @include breakpoint(large) {
    display: flex;
    justify-content: flex-end;

    .scrolled & {
      transform: translateY(20%);
    }

    .scrolled--down & {
      transform: translateY(-40%);
    }
  }

  @include breakpoint(large) {
    padding-left: 3rem;
  }
}

.navigation__item {
  list-style-type: none;
  margin: 0;

  @include breakpoint(large) {
    margin-bottom: 0.1rem;
    text-align: center;
    padding: 0 2rem;

    &:last-child {
      padding-right: 1rem;
    }
  }
}

.navigation__link {
  position: static;
  display: block;
  color: $black;
  font-size: rem-calc(27px);
  opacity: 0;
  transition-delay: 500ms;
  width: min-content;
  padding: 0.3rem 0 0.1rem;

  .active & {
    color: $red;
    .page--region & {
      border-bottom: 0.15rem solid $white;
    }
  }

  @include breakpoint(large) {
    font-size: rem-calc(18px);
    opacity: 1;
    transition: opacity 300ms ease-in-out;
    color: $gray7b;
    width: auto;
    .page--region & {
      color: $white;
    }
    &:hover,
    &.active {
      color: $black;
      .page--region & {
        color: $white;
      }
    }
  }
  .nav-is-open & {
    opacity: 1;
    color: $white;
  }
}

.navigation__address {
  display: none;
  flex-direction: column;
  order: 3;
  margin-top: 1rem;

  h3 {
    color: $white;
  }

  span,
  a {
    @extend %body-font-small;
    color: transparentize($white, 0.4);
    display: block;
  }

  .nav-is-open & {
    display: flex;
  }
}

.navigation__logo {
  display: block;
  img {
    height: 100%;
    transition: all 0.2s ease-in;
    transform-origin: 0 30%;
    width: 6rem;
    transform: scale(0.6);

    .at-the-top & {
      transform: scale(1);
    }
  }
  @include breakpoint(medium down) {
    padding-left: 0.3rem;
    transform: translateY(-8px);
    img {
      width: 4rem;
      transform: scale(0.9);
    }
  }
}

// Navigation Toggle
// =================

$burger-menu-width: rem-calc(35px);
$burger-menu-height: rem-calc(35px);
$burger-menu-line-height: rem-calc(6px);
$burger-menu-mobile-ratio: 1.5;

.nav__trigger {
  display: flex;
  align-items: center;
  position: relative;
  transition:
    width 200ms ease,
    height 200ms ease;
  width: math.div($burger-menu-width, $burger-menu-mobile-ratio);
  height: math.div($burger-menu-height, $burger-menu-mobile-ratio);

  @include breakpoint(medium) {
    width: $burger-menu-width;
    height: $burger-menu-height;

    &.nav__trigger--small {
      top: 0.4rem;
      width: math.div($burger-menu-width, $burger-menu-mobile-ratio);
      height: math.div($burger-menu-height, $burger-menu-mobile-ratio);
    }
  }
  .overlay--open & {
    transition-delay: 0.2s;
    opacity: 1;
    transform: rotate(0);
  }
}

.nav__icon {
  display: inline-block;
  position: relative;
  width: math.div($burger-menu-width, $burger-menu-mobile-ratio);
  height: math.div($burger-menu-line-height, $burger-menu-mobile-ratio);

  @include breakpoint(medium) {
    width: $burger-menu-width;
    height: $burger-menu-line-height;

    .nav__trigger--small & {
      width: math.div($burger-menu-width, $burger-menu-mobile-ratio);
      height: math.div($burger-menu-line-height, $burger-menu-mobile-ratio);
    }
  }
  @extend %mix-blend-color;

  .page--region & {
    background: $white;
  }

  &:before,
  &:after {
    content: "";
    display: block;
    width: math.div($burger-menu-width, $burger-menu-mobile-ratio);
    height: math.div($burger-menu-line-height, $burger-menu-mobile-ratio);

    @include breakpoint(medium) {
      width: $burger-menu-width;
      height: $burger-menu-line-height;

      .nav__trigger--small & {
        width: math.div($burger-menu-width, $burger-menu-mobile-ratio);
        height: math.div($burger-menu-line-height, $burger-menu-mobile-ratio);
      }
    }
    position: absolute;
    background: $primary-color;
    transition-property: margin, transform, opacity;
    transition-duration: 300ms;

    .overlay--open & {
      background: $white;
    }

    .nav__trigger--small & {
      background-color: $red;
    }

    .overlay--sticky & {
      background-color: $white;
    }

    .page--region & {
      background: $white;
    }
  }

  &:before {
    margin-top: rem-calc(-8px);

    @include breakpoint(medium) {
      margin-top: rem-calc(-13px);

      .nav__trigger--small & {
        margin-top: rem-calc(-8px);
      }
    }
  }

  &:after {
    margin-top: rem-calc(8px);

    @include breakpoint(medium) {
      margin-top: rem-calc(13px);

      .nav__trigger--small & {
        margin-top: rem-calc(8px);
      }
    }
  }

  .overlay--open & {
    background: none;

    &:before {
      margin-top: 0;
      transform: rotate(45deg);
    }

    &:after {
      margin-top: 0;
      transform: rotate(-45deg);
    }
  }
}

.collaboration-link__container {
  position: fixed;
  right: 0;
  top: 40%;
  z-index: 10000;
  transform-origin: right;
  transform: rotate(-90deg) translate(50%, -50%);
  display: flex;
}

.collaboration-link__wrapper {
  position: relative;
  &:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(229, 85, 79, 0.78);
  }

  &:hover {
    &:before {
      background-color: rgba(229, 85, 79, 1);
    }
  }
  &:not(:last-child) {
    margin-right: rem-calc(10);
  }
}

.collaboration-link {
  position: relative;
  z-index: 10001;
  isolation: isolate;
  a {
    position: relative;
    display: block;
    padding: 0.15rem 1rem;
    color: $white;
    z-index: 10001;
    isolation: isolate;
    font-size: $body-font-size-tiny;

    @include breakpoint(medium) {
      font-size: $body-font-size;
      padding: 0.25rem 1rem;
    }
  }
}
@keyframes wobble-horizontal {
  16.65% {
    -webkit-transform: translateX(8px);
    transform: translateX(8px);
  }
  33.3% {
    -webkit-transform: translateX(-6px);
    transform: translateX(-6px);
  }
  49.95% {
    -webkit-transform: translateX(4px);
    transform: translateX(4px);
  }
  66.6% {
    -webkit-transform: translateX(-2px);
    transform: translateX(-2px);
  }
  83.25% {
    -webkit-transform: translateX(1px);
    transform: translateX(1px);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
.wobble-horizontal {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-animation-name: wobble-horizontal;
  animation-name: wobble-horizontal;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
}

.rb {
  --_size: 8rem;

  position: absolute;
  z-index: 10002;

  top: calc(63vh - var(--_size) - 30px);
  /* Container is 75rem, half is 37.5rem */
  left: calc(50vw + min(50vw, 37.5rem) - var(--_size) - 50px);

  @include breakpoint(medium) {
    --_size: 10rem;
    top: calc(68vh - var(--_size) - 30px);
  }

  @include breakpoint(large) {
    top: calc(73vh - var(--_size) - 30px);
  }

  background-color: rgba(229, 85, 79, 0.78);
  color: #fff;
  display: inline-grid;
  place-items: center;
  text-align: center;
  font-size: 1.25em;
  font-weight: $semibold;

  width: var(--_size);
  height: var(--_size);
  border-radius: 999px;
  opacity: 0;

  transition: opacity 0.2s;

  animation: 20s 1s 1 forwards rb-rolling;

  &:hover {
    background-color: rgba(229, 85, 79, 1);
  }
}

@keyframes rb-rolling {
  0% {
    opacity: 1;
    transform: translateX(400px) rotate(720deg);
  }

  7% {
    opacity: 1;
    transform: translateX(-5px) rotate(-20deg);
  }

  8%,
  100% {
    opacity: 1;
    transform: translateX(0) rotate(-15deg);
  }

  24.5%,
  28%,
  44.5%,
  48%,
  64.5%,
  68%,
  84.5%,
  88% {
    transform: scale(1) rotate(-15deg);
  }
  25%,
  45%,
  65%,
  85% {
    transform: scale(0.7) rotate(-15deg);
  }
  26.5%,
  46.5%,
  66.5%,
  86.5% {
    transform: scale(1.03) rotate(-15deg);
  }
}
