.paperform {
  min-height: 30rem;
}

/*
.Paperform--locked {
  overflow: hidden !important;
}

.Paperform__popupwrapper {
  position: fixed !important;
  //opacity: 0 !important;
  transition: opacity 200ms ease-in;
  //pointer-events: none !important;
  @media (max-width: 600px) {
    position: fixed !important;
  }
  &.active {
    pointer-events: auto !important;
    opacity: 1 !important;
  }
}
*/

.Paperform__popupcontent {
  position: relative;
  padding: 3rem 3rem 2rem !important;
  background: $white !important;
  margin: 70px auto !important;
  transition: all ease-in 200ms;
  max-width: 1200px !important;

  @media (max-width: 600px) {
    padding: 2.5rem 2rem !important;
    min-height: 20rem !important;
  }
}

.Paperform__popupclose1,
.Paperform__popupclose2 {
  border-bottom: 2px solid $black !important;
}

.Paperform__popupclose {
  position: absolute !important;
  width: 25px !important;
  height: 25px !important;
  right: 30px !important;
  top: 30px !important;
  color: $black !important;
  opacity: 1 !important;
}
