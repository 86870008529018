.subpage-teaser {
  display: flex;
  flex-direction: column;
}

.subpage-teaser--grid {
  display: block;

  .subpage-teaser--cell {
    padding-bottom: $grid-gap;
  }

  .teaser {
    display: block;
    height: 100%;
  }

  .teaser__item {
    height: 100%;
  }
}

.subpage-teaser__item {
  position: relative;
  display: flex;
  align-items: center;
  background: $white;
  margin-bottom: $outline-width;

  &:after {
    content: "";
    position: absolute;
    background: transparentize($primary-color, 0.3);
    bottom: 0;
    right: 0;
    width: 4.55rem;
    height: 4.5rem;
    z-index: 10;
    clip-path: polygon(100% 0, 0% 100%, 100% 100%);
    transition: all 0.2s ease-in;

    @include breakpoint(medium) {
      width: 2.5rem;
      height: 2.5rem;
    }
  }
  &:before {
    content: "+";
    position: absolute;
    font-family: "Open Sans", Helvetica, sans-serif;
    font-size: 32px;
    font-weight: 900;
    color: $white;
    bottom: 0;
    right: 0.4rem;
    z-index: 20;
    @include breakpoint(medium) {
      font-size: 22px;
      bottom: 0;
      right: 0.2rem;
    }
  }

  &:hover {
    &:after {
      background: $primary-color;
    }
  }
}

.subpage-teaser__image-container {
  display: flex;
  align-items: center;
  background: $primary-color;
  height: 5rem;
  margin-right: 1rem;

  @include breakpoint(medium) {
    min-width: 10rem;
    max-width: 10rem;
  }

  img {
    display: none;
    @include breakpoint(medium) {
      display: block;
    }
  }

  .subpage__teaser-item--second & {
    background: transparentize($primary-color, 0.3);
  }

  .subpage__teaser-item--third & {
    background: transparentize($primary-color, 0.5);
  }
}

.subpage-teaser__image {
  height: 2rem;
  width: 100%;
}

.subpage-teaser__title {
  color: $black;
  margin-right: 2rem;
  min-width: 9rem;

  span {
    font-weight: $bold;
    hyphens: auto;
  }

  @include breakpoint(large) {
    flex: 1 1 25%;
    min-width: auto;
  }
}

.subpage-teaser__content {
  color: $gray7b;
  padding: 0.25rem 4rem 0.25rem 0;
  @extend %body-font-small;
  flex: 1 1 75%;

  p {
    margin: 0;
  }

  @include breakpoint(small only) {
    display: none;
  }
}
