@use "sass:math";

.article__main-contents {
  @include resprop("margin-top", (0, 0, math.div($general-margin, 1.5)));

  a {
    color: $primary-color;
  }

  &.article__main-contents--no-image {
    margin-top: 0;
  }
}

.article__info {
  @extend %body-font-small;
  margin-bottom: 0.5rem;
  margin-left: 0.25rem;
}

.article__image-container {
  position: relative;
  @include resprop("height", (15rem, 28rem, 35rem));
}

.article__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  //outline: $outline-width solid $lightgray;
  //outline-offset: -$grid-gap-small;

  &.article--lightblue {
    background-color: $lightblue;
  }
  &.article--red {
    background-color: $red;
  }
  &.article--blue {
    background-color: $blue;
  }
  &.article--beige {
    background-color: $beige;
  }
}

.article__content-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  padding: 1rem;
  width: 100%;
  height: 100%;

  @include breakpoint(medium) {
    padding: 2.5rem;
  }
}

.article__inner-wrapper {
  position: relative;
  padding: 1rem;
  width: 100%;
  height: 100%;

  @include breakpoint(medium) {
    padding: 2.5rem;
  }
}

.article__copyright {
  position: relative;

  @include breakpoint(large) {
    position: absolute;
  }
}

.article__title {
  position: absolute;
  @include resprop(
    "bottom",
    ($grid-gap-small, $grid-gap-small * 2, $grid-gap-small * 3)
  );
  //@include resprop("padding", ($grid-gap-small / 3, $grid-gap-small / 1.5));
  @include resprop("max-width", (80%, 60%));
  margin-left: $grid-gap-small;
  h1 {
    margin: 0;
    background-color: rgba(255, 255, 255, 0.6);
    background-repeat: no-repeat;
    text-shadow: none;
    box-decoration-break: clone;
    display: inline;
    padding: 0.53rem 1rem;
    line-height: 1.9;

    @include breakpoint(medium) {
      line-height: 1.7;
      padding: 0.65rem 1rem;
    }
  }
}

.article__head {
  margin-bottom: 1rem;

  @include breakpoint(large) {
    margin-bottom: 0;
  }
}
