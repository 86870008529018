@use "sass:math";

.teaser__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  @include resprop(
    "margin-bottom",
    (math.div($general-margin, 3), math.div($general-margin, 1.5))
  );
}

.service-teaser {
  display: grid;
  grid-template-columns: repeat(1, 1fr);

  @include breakpoint(medium) {
    grid-template-columns: repeat(2, 1fr);
  }

  @include breakpoint(large) {
    grid-template-columns: repeat(3, 1fr);
  }

  a {
    margin-bottom: 4rem;

    @include breakpoint(medium) {
      margin-bottom: 0;
    }
  }
}

.service-teaser__item {
  display: flex;
  align-items: center;
  color: $black;
  width: 20rem;

  @include breakpoint(medium) {
    margin-bottom: 3rem;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    min-width: 20rem;
    width: auto;
  }
}

.service-teaser__content-wrapper {
  @include breakpoint(small only) {
    flex: 1 1 50%;
  }
}

.service-teaser__title {
  @include breakpoint(small only) {
    hyphens: auto;
    h3 {
      font-size: rem-calc(20);
    }
  }
}

.service-teaser__image-container {
  margin-bottom: 1rem;

  @include breakpoint(small only) {
    flex: 1 1 20%;
    margin-right: 0.5rem;
  }
}

.service-teaser__image {
  display: block;
  margin: 0 auto;
  height: 3rem;
  @include breakpoint(medium) {
    max-width: 6rem;
    height: 6rem;
  }
}

.service-teaser__content {
  @extend %body-font-small;
}

.service-teaser__link {
  @extend %body-font-small;
  color: $primary-color;
}
