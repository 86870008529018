// will output the property for increasing breakpoint sizes
// (breakpoints defined in $breakpoints for foundation)
// example usage:
// h1 {
//   @include resprop('font-size', (2.5rem, 3rem));
//   @include resprop('text-align', (start, center));
// }
@use "sass:list";

//ok, newer versions of sass makes other execution orders, so i did have to set the breakpoints again!
$bpoints: (0 640px 1024px 1200px 1980px 3000px);

@mixin resprop($property, $values) {
  $i: 1;

  @each $value in $values {
    $bpoint: list.nth($bpoints, $i);
    @media screen and (min-width: #{$bpoint}) {
      #{$property}: $value;
    }
    $i: $i + 1;
  }
}

%link-in-text {
  color: $primary-color;
  font-weight: bold;
  text-decoration: underline;

  &:hover {
    text-decoration: underline;
  }
}

%link-in-text-hover {
  text-decoration: underline;
}

.regular-link {
  @extend %link-in-text;
}

%transition-all-ease {
  transition: all 0.2s ease;
}

@each $breakpoint in $breakpoint-classes {
  @if $breakpoint == small {
    // Do nothing
  } @else {
    @include breakpoint($breakpoint) {
      .#{$breakpoint}-shrink {
        flex: 0 0 auto;
        max-width: 100%;
      }
    }
  }
}
