$black: #292828;
$gray: #9b9b9b;
$lightgray: #f7f7f7;
$white: #ffffff;
$gray7b: #7b7b7b;

// main colors
$red: #e5554f;
$darkred: #db3837;
$lightred: #ff8281;
$rosa: #ffbdbd;
$beige: #ece4d3;
$blue: #499cb9;
$lightblue: #d5e0e0;
$azure: #2f5da3;
$light-grayish-orange: #f6f3ee;

$periwinkle-gray: #bfd2e8;
$foggy-gray: #ccd0b7;
$seashell: #f6f6f6;

// button hover
$persimmon: #ff5655;

// edit button
$pickled-bluewood: #2a3d53;

// Partners

$teaser-background-color: $light-grayish-orange;

$gold: #f3ddb0;
$silver: #cecece;
$bronze: #f3c4b0;

$primary-color: $red;
$background-color: $seashell;
$event-color: $lightblue;
$team-color: $blue;

$color-facebook: rgb(59, 89, 152);
$color-twitter: rgb(29, 161, 242);
$color-whatsapp: rgb(77, 194, 71);
$color-email: $primary-color;

$icon-size: 50px;
$icon-size-mobile: 70px;

$border-width: 0.4rem;
// $border-radius: 0.1rem;
$border-radius: 0;

// margins
//$general-margin-text: 1.5rem;
$general-margin: rem-calc(80px);
$general-margin-mobile: $padding-gutter-small;
// grid
$teaser-padding: rem-calc(33px);
$grid-gap: rem-calc(20px);
$grid-gap-small: rem-calc(16px);

// outline

$outline-width: 3px;

$claw-thickness: rem-calc(6px);
$claw-thickness-mobile: 1.5;
$claw-width: 4rem;

.plugin-centered {
  text-align: center;
}
