.edit-tools {
  position: fixed;
  z-index: 10;
  right: 0.5rem;
  bottom: 0.5rem;
  background: rgba($primary-color, 0.5);
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  font-weight: bold;
  border-radius: $border-radius;
  color: $white;

  span,
  .button {
    margin: 0 0.125rem;
    padding: 0.125em 0.25em;
  }

  .button {
    background: $primary-color;
    min-width: auto;
    border-radius: $border-radius;
    border-color: $primary-color;
  }

  .button {
    display: none;
  }

  &:hover .button {
    display: inline-block;
  }
}
