button,
.button,
input[type="submit"],
%button {
  display: inline-block;
  color: $primary-color;
  cursor: pointer;
  text-align: center;
  border-radius: $border-radius;
  font-size: rem-calc(18px);
  padding: 0.8rem 2.5rem;
  border: 3px solid $primary-color;
  font-weight: $bold;

  @extend %transition-all-ease;

  &:hover {
    color: $white;
    background: $primary-color;
  }

  &.button--white {
    color: $white;
    border: 3px solid $white;

    &:hover {
      color: $primary-color;
      background: $white;
    }
  }

  &.button--cover {
    color: $white;
    background: $primary-color;

    &:hover {
      color: $primary-color;
      background: $white;
    }
  }

  &.button--claws {
    position: relative;
    border: none;

    &:hover {
      background: none;
      color: $primary-color;

      .button--claw {
        &:before,
        &:after {
          width: $claw-width * 0.25;
        }
      }
    }

    &.button--claws--white {
      color: $white;
      &:hover {
        background: none;
        color: $white;
      }
    }
  }

  //.buttons & {
  //  margin-right: $grid-gap;
  //}

  &.button--moodboard {
    text-align: inherit;
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
  }

  &.button--margins {
    margin: 1rem 0;
  }
}

.button--claw {
  display: block;
  position: absolute;
  height: 100%;
  width: $claw-thickness * 0.5;
  background-color: $primary-color;

  .button--claws--white & {
    background-color: $white;
  }

  &:before,
  &:after {
    content: "";
    position: absolute;
    width: 10rem;
    height: $claw-thickness * 0.5;
    background-color: $primary-color;
    transition: width 0.3s;

    .button--claws--white & {
      background-color: $white;
    }
  }

  &:before {
    top: 0;
  }

  &:after {
    bottom: 0;
  }
}

.button--claw-left {
  top: 0;
  left: 0;

  &:before,
  &:after {
    left: $claw-thickness * 0.5;
  }
}

.button--claw-right {
  top: 0;
  right: 0;

  &:before,
  &:after {
    right: $claw-thickness * 0.5;
  }
}

%button--secondary-active {
  color: $white;
  border-color: $primary-color;
  background: $primary-color;
}

.button--secondary,
%button--secondary {
  border: $outline-width solid $primary-color;
  color: $primary-color;

  &:hover,
  &.active {
    @extend %button--secondary-active;
  }
}

// .button--red {
//   border-color: $primary-color;
//   background: $primary-color;
//   color: $white;

//   &:hover {
//     color: $black;
//     border-color: $black;
//   }
// }

.button--small {
  padding: 0.7rem 1.4rem;
}

.content__button {
  padding: 1.5rem 2.8rem 1.5rem 2.8rem;
  margin-bottom: 2rem;
  margin-top: 2rem;
  a {
    font-size: 1.25rem;
  }
}

.button-container {
  display: flex;
  justify-content: center;
}
