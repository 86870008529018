@use "sass:math";

form {
  label {
    &.required {
      font-weight: bold;
    }

    &.required::after {
      content: "*";
    }

    @include breakpoint(large) {
      display: block;
      text-align: right;
    }
  }

  [class*="widget"] {
    margin-bottom: 0.5rem;
  }

  // unlucky nesting requires this
  $form-elements: "input[type=tel], input[type=text], input[type=email], input[type=number], input[type=url], input[type=password], textarea, select";

  #{$form-elements} {
    // border: 1px solid $gray;
    border: none;
    background: $lightgray;
    padding: 1rem 1rem;
    width: 100%;
    max-width: 40rem;
    @extend %body-font-small;

    &::placeholder {
      color: $black;
    }
  }

  input[type="file"] {
    max-width: 25rem;
    padding: 0.4rem;
  }

  .error {
    label.error {
      color: $primary-color;
    }

    #{$form-elements} {
      border-color: $primary-color;
    }

    input:required {
      box-shadow: none !important;
    }
    input:invalid {
      border-color: $primary-color;
    }
  }

  .errors,
  .errorlist {
    list-style: none;
    color: $primary-color;
    font-size: 16px;
  }

  .errorlist + .help-text {
    margin-top: 1.2rem;
    font-size: 16px;
  }

  .help-text {
    margin: 0.3rem 0;
    opacity: 0.7;
    font-size: 16px;

    @include breakpoint(large) {
      margin-top: 0.3rem;
    }
  }

  input[type="checkbox"] {
    appearance: none;
    background: url("img/icons/checkbox.svg") no-repeat 94% 50%;
    width: 26px;
    height: 26px;
    outline: none;
    margin-right: 0.75rem;
    transition: all 0.2s ease;

    &:checked {
      outline: none;
      background: url("img/icons/checkbox-checked.svg") no-repeat 94% 50%;
    }

    & ~ label {
      font-weight: normal;
      text-align: left;
    }

    &.input-is-filled ~ label {
      font-size: 16px;
      color: $gray;
    }
  }

  .field-wrapper select {
    background: url("img/icons/expand.svg") no-repeat 96% 50%;
  }

  select {
    appearance: none;
    border-radius: $border-radius;
    overflow: hidden;
  }

  textarea {
    word-wrap: break-word;
    overflow: hidden;
  }
}

// NL Form

.newsletter-form {
  @include resprop(
    "padding-bottom",
    (math.div($general-margin, 1.5), $general-margin)
  );
}

.newsletter-form__checkboxes {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
  flex-wrap: wrap;

  @include breakpoint(medium) {
    flex-wrap: nowrap;
  }
}

.newsletter-form__checkbox {
  @extend %body-font-small;
  display: flex;
  align-items: center;
  margin-right: 1rem;
}

.newsletter-form__items {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  @include breakpoint(medium) {
    flex-wrap: nowrap;
  }
}

.newsletter-form__item {
  &.newsletter-form__item--select {
    position: relative;

    &:after {
      content: "";
      display: block;
      width: 1.25rem;
      height: 1.25rem;
      position: absolute;
      top: 1rem;
      right: 1rem;
      background: url("img/icons/arrow-down.svg") center no-repeat;
      background-size: contain;
    }
  }

  &.newsletter-form__item--3 {
    flex: 0 1 100%;
    margin-bottom: 1rem;

    @include breakpoint(medium) {
      flex: 0 1 32%;
      margin-bottom: 2rem;
    }
  }
}
.newsletter-form__group {
  display: flex;
  width: 100%;
}
.newsletter-form__email {
  flex: 1 1 80%;
}
.newsletter-form__submit {
  flex: 1 1 20%;
}

.property-form {
  margin-top: -2rem;
}
