@use "sass:math";

.box__content {
  text-align: center;
  background: $teaser-background-color;
  padding: $grid-gap;
  display: flex;
  flex-direction: column;
}

.box__inner {
  background: $white;
  position: relative;
  padding: 0;
  flex-direction: column;
  display: flex;
  margin-top: 0;

  @include breakpoint(large) {
    margin-top: 2rem;
  }

  @include breakpoint(medium) {
    flex-direction: row;
  }
  @include breakpoint(large) {
    flex-direction: column;
    padding: 0;
    //padding: ($grid-gap / 2) ($grid-gap / 2) 2rem ($grid-gap / 2);
  }
}

.box__image-container {
  background-color: $white;
  height: 100%;

  margin-top: 2rem;

  @include breakpoint(medium) {
    margin-top: 0;
  }

  img {
    height: 15rem;
    width: 100%;
    object-fit: contain;
    flex: 1 1 60%;
    display: block;
    @include breakpoint(medium) {
      height: 12rem;
    }
    @include breakpoint(large) {
      height: 10rem;
      flex: auto;
    }
  }

  @include breakpoint(medium) {
    flex: 1 1 30%;
  }

  .article--lightblue & {
    background-color: $lightblue;
  }
  .article--red & {
    background-color: $red;
  }
  .article--blue & {
    background-color: $blue;
  }
  .article--beige & {
    background-color: $beige;
  }
}

.box__header {
  //outline: $outline-width solid $seashell;
  //outline-offset: -$grid-gap / 2;
  padding: 2rem ($grid-gap * 2) 1rem ($grid-gap * 2);

  &.box_header--no-image {
    margin-top: 0;
  }

  .article--event & {
    margin-top: 0;
  }

  h3 {
    color: $primary-color;

    &.title--smaller {
      font-size: 120%;
    }
  }

  p {
    @extend %body-font-small;
    margin-bottom: 1rem;
  }

  @include breakpoint(medium) {
    position: relative;
    padding: 2rem ($grid-gap * 2) 1rem ($grid-gap * 2);
    flex: 1 1 70%;
    .article--event & {
      margin-left: 0;
    }
  }

  @include breakpoint(large) {
    //outline: $outline-width solid $seashell;
    padding: 2rem 2rem 1rem 2rem;
    margin-left: 0;
    flex: auto;
  }
}

.box_header--claws {
  position: relative;
  padding: 0.25rem 0;
}

.box_header--claw {
  display: block;
  position: absolute;
  height: 100%;
  width: $claw-thickness * 0.5;
  background-color: $primary-color;

  .box_header--claws--white & {
    background-color: $white;
  }

  &:before,
  &:after {
    content: "";
    position: absolute;
    width: math.div($claw-width, 6);
    height: $claw-thickness * 0.5;
    background-color: $primary-color;
    transition: width 0.3s;

    .box_header--claws--white & {
      background-color: $white;
    }
  }

  &:before {
    top: 0;
  }

  &:after {
    bottom: 0;
  }
}

.box_header--claw-left {
  top: 0;
  left: 0;

  &:before,
  &:after {
    left: $claw-thickness * 0.5;
  }
}

.box_header--claw-right {
  top: 0;
  right: 0;

  &:before,
  &:after {
    right: $claw-thickness * 0.5;
  }
}

.box__social {
  display: flex;
  justify-content: center;
  padding: 0.4rem;
  a {
    margin: 0 0.25rem;
  }
}

.box__inner--info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: $white;
  //padding-top: 1rem;
  @include breakpoint(medium) {
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
    padding-top: 1rem;
    .box__info {
      flex: 1 1 25%;
    }
    //padding-top: 2rem;
  }

  @include breakpoint(large) {
    justify-content: center;
    flex-direction: column;
    flex-wrap: nowrap;
    padding-top: 0;
    .box__info {
      flex: auto;
    }
  }
}

.box__info {
  margin-bottom: 2.6rem;
  padding: 0 0.5rem;

  span,
  a {
    @extend %body-font-small;
  }

  a {
    color: $primary-color;
  }

  address {
    @extend %body-font-small;
  }

  &.box__info--first {
    margin-top: 1rem;
  }
}

.box__subtitle {
  font-weight: 700;
  margin-bottom: 0.5rem;
}

.article__others {
  padding-top: $general-margin;
  padding-bottom: $general-margin * 0.5;

  &.article__others--event {
    margin-top: $general-margin;
  }
}

.article-box {
  @include breakpoint(medium down) {
    margin-bottom: 2rem;
  }
}

.article-box--company {
  //@include breakpoint(medium down) {
  //  margin-top: 3rem;
  //}
}
