.newsletter-teaser {
  position: relative;
  text-align: center;
  padding: 3rem;

  &:before {
    content: "";
    position: absolute;
    top: 1rem;
    left: 1rem;
    bottom: 1rem;
    right: 1rem;
    outline: 2px solid $white;
    z-index: 0;
  }
}

.newsletter-teaser--economy {
  background-color: $azure;
  color: $white;
}

.newsletter-teaser--culture {
  background-color: $red;
  color: $white;

  .teaser__agenda & {
    margin-top: $grid-gap;
  }
}

.newsletter-teaser__title,
.newsletter-teaser__description,
.newsletter-teaser__button {
  position: relative;
}

.newsletter-teaser__title {
  margin-bottom: $general-margin * 0.5;
}

.newsletter-teaser__description {
  font-size: $body-font-size-small;
  margin-bottom: 2rem;

  @include breakpoint(medium) {
    font-size: $body-font-size;
  }
}

.newsletter-teaser__button {
  background-color: $white;

  &:hover {
    opacity: 0.7;
    background-color: $white;
  }

  .newsletter-teaser--economy & {
    color: $azure;
  }

  .newsletter-teaser--culture & {
    color: $red;
  }
}

.social-icons {
  display: flex;

  svg {
    width: 2.5rem;
    height: 2.5rem;
    path {
      fill: $primary-color !important;
    }
    g {
      stroke: $primary-color;
      fill: transparent !important;
    }
  }

  &.social-icons--white {
    svg {
      path {
        fill: $white !important;
      }
      g {
        stroke: $white;
        fill: transparent !important;
      }
    }
  }

  &.social-icons--small {
    svg {
      width: 2rem;
      height: 2rem;
    }
  }
}
