.team-item__container {
  margin-bottom: 3rem;
}

.team-item__image-wrapper {
  margin-bottom: 1.5rem;
}

.team-item__wrapper {
}

.team-item__marker {
  height: 3px;
  width: 4rem;
  background-color: $gray;
  margin-bottom: 1rem;
}

.team-item__name {
  //color: $team-color;
  h3 {
    margin-bottom: 0.3rem;
  }
}

.team-item__sub {
  margin-bottom: 1rem;
  //color: $team-color;
  p {
    margin-bottom: 0;
  }
}

.team-item__phone {
  @extend %body-font-small;
  margin-bottom: 0.5rem;
}

.team-item__text,
.team-item__url,
.team-item__email {
  @extend %body-font-small;
}
