@use "sass:math";

$width: 300px; // Regular: 250px
$border-width: 1px;
$radius: $width * 0.016891; // 296px / 5px ratio.
$transition: 0.3s;
$font-size: $width * 0.056;
$offset: 3.5rem;

.form-calendar__filter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h4 {
    text-align: center;
    margin-bottom: 1.5rem;
  }
}

.form-calendar__datepicker {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;
  .form-calendar--input {
    position: absolute;
    opacity: 0;
    height: 0;
    padding: 0;
  }
}

.form-calendar__reset {
  position: relative;
  order: 3;
  display: flex;
  width: $width;
  z-index: 101;
  margin-top: -1rem;

  a {
    width: 100%;
  }
}

.form-calendar__filter-button {
  .button {
    border-top-width: 1.5px;
    border-bottom-width: 1.5px;
  }

  &:first-child {
    .button {
      border-top-width: 3px;
    }
  }

  &:last-child {
    .button {
      border-bottom-width: 3px;
    }
  }
}

.form-calendar__radio {
  position: absolute;
  visibility: hidden;
}

.form-calendar__label {
  display: block;
  width: $width;

  .form-calendar__radio:checked + & {
    @extend %button--secondary-active;
  }
}

.qs-datepicker {
  color: black;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  font-family: $font-body;
  font-size: $font-size;
  z-index: 9001;
  user-select: none;
  overflow: hidden;
  background: white;
  background: none;
  padding-top: 0.5rem;
  padding-bottom: $offset;

  * {
    box-sizing: border-box;
  }

  &.qs-centered {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &.qs-hidden {
    display: none;
  }

  .qs-overlay {
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.75);
    color: white;
    width: 100%;
    height: 100%;
    padding: 0.5em;
    z-index: 1;
    opacity: 1;
    transition: opacity $transition;
    display: flex;
    flex-direction: column;

    &.qs-hidden {
      opacity: 0;
      z-index: -1;
    }

    .qs-overlay-year {
      border: none;
      background: transparent;
      border-bottom: 1px solid white;
      border-radius: 0;
      color: white;
      font-size: $font-size;
      padding: 0.25em 0;
      width: 80%;
      text-align: center;
      margin: 0 auto;
      display: block;

      // https://goo.gl/oUuGkG
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
      }
    }

    .qs-close {
      padding: 0.5em;
      cursor: pointer;
      position: absolute;
      top: 0;
      right: 0;
    }

    .qs-submit {
      border: 1px solid white;
      border-radius: $radius;
      padding: 0.5em;
      margin: 0 auto auto;
      cursor: pointer;
      background: rgba(128, 128, 128, 0.4);

      &.qs-disabled {
        color: gray;
        border-color: gray;
        cursor: not-allowed;
      }
    }

    .qs-overlay-month-container {
      display: flex;
      flex-wrap: wrap;
      flex-grow: 1;
    }

    .qs-overlay-month {
      display: flex;
      justify-content: center;
      align-items: center;
      width: calc(100% / 3);
      cursor: pointer;
      opacity: 0.5;
      transition: opacity $transition * 0.5;

      &.active,
      &:hover {
        opacity: 1;
      }
    }
  }

  .qs-controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    width: 100%;
    padding: 0.25rem 1rem;
    flex-grow: 1;
    flex-shrink: 0;
    filter: blur(0px);
    transition: filter $transition;

    &.qs-blur {
      filter: blur(5px);
    }

    &:after {
      content: "";
      position: absolute;
      width: 100%;
      height: 4.5rem;
      top: 0;
      left: 0;
      background: $teaser-background-color;
    }
  }

  .qs-arrow {
    position: relative;
    display: flex;
    align-items: center;
    height: 2rem;
    width: 1rem;
    cursor: pointer;
    transition: background 0.15s;
    z-index: 100;

    &.qs-left:after {
      content: url("img/icons/expand-left.svg");
    }

    &.qs-right:after {
      content: url("img/icons/expand-right.svg");
    }
  }

  .qs-month-year {
    font-weight: bold;
    transition: border 0.2s;
    border-bottom: 1px solid transparent;
    z-index: 100;
  }

  .qs-month {
    padding-right: 0.5ex;
  }

  .qs-year {
  }

  .qs-squares {
    display: flex;
    flex-wrap: wrap;
    padding: 0 6px 6px 6px;
    filter: blur(0px);
    transition: filter $transition;

    &.qs-blur {
      filter: blur(5px);
    }
  }

  .qs-square {
    width: math.div(100%, 7);
    height: $width * 0.125;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &.qs-current {
      font-weight: bold;
    }

    &.qs-num {
      span {
        display: block;
        background: $white;
        width: 1.8rem;
        height: 1.8rem;
        text-align: center;
        padding-top: 0.2rem;
        transition: all 0.1s;
      }
    }

    &.qs-active {
      span {
        color: $white;
        background: $red;
      }
    }

    &.qs-disabled {
      background: none;
      span {
        opacity: 0.2;
        display: block;
        background: none;
        width: 1.8rem;
        height: 1.8rem;
        text-align: center;
        padding-top: 0.2rem;
      }
    }

    &.qs-empty {
      cursor: default;
      background: none;
    }

    &.qs-disabled {
      cursor: not-allowed;
    }

    &.qs-day {
      cursor: default;
    }

    &:not(.qs-empty):not(.qs-disabled):not(.qs-day):not(.qs-active) {
      span {
        &:hover {
          color: $white;
          background: $red;
        }
      }
    }
  }
}

.empty-message {
  display: flex;
  align-items: center;
  padding: 0.5rem 2rem;
  height: 5rem;
  background: $teaser-background-color;

  &.empty-message--articles {
    margin-top: -2rem;
  }

  @include breakpoint(large) {
    grid-column-end: 3;
    grid-column-start: 1;
  }
  span {
    @extend %body-font-small;
  }
}

.teaser__agenda {
  background-color: $teaser-background-color;
}
