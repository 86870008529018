@use "sass:math";

body {
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  min-height: 100vh;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1000;
    pointer-events: none;
    opacity: 0;
    mix-blend-mode: multiply;
    background-color: rgba(229, 85, 79, 0.9);
  }

  &.page--region {
    &:after {
      opacity: 1;
      height: 5rem;
    }
  }

  &.overlay--sticky {
    overflow: hidden;
    @include breakpoint(medium) {
      padding-right: 15px;
    }
    &:after {
      height: 100%;
      opacity: 1;
    }
  }

  &.nav-is-open {
    position: fixed;
    overflow: initial !important;
  }

  &.fixed {
    position: fixed;
  }
}

.main {
  flex: 1 0 auto; // Fill height
  //overflow-x: hidden;
  overflow: hidden;
  .overlay--sticky & {
    filter: blur(3px);
  }
  //
  //.page--home &,
  //.article-list &,
  //.page--standard & {
  //  background: $background-color;
  //}

  .page--region & {
    background: $lightred;
  }

  .page--white & {
    background: none;
  }
  //
  //@include breakpoint(large) {
  //  padding-top: 8rem;
  //}
}

.main-padding--top {
  padding-top: 4rem;
  @include breakpoint(medium) {
    padding-top: 6rem;
  }
}

.section {
  &.section--margin {
    margin-bottom: $general-margin;
  }

  .article-list & {
    margin-bottom: 0;
  }

  &.section--background {
    padding: $general-margin 0;
    background-color: $teaser-background-color;
  }
}

.page-title-margin--bottom {
  margin-bottom: rem-calc(30px);
  @include breakpoint(medium) {
    margin-bottom: rem-calc(60px);
  }
}

.main__scrollspy {
  position: absolute;
  top: 0;
  left: 0;
  width: 1px;
  height: 4rem;
  visibility: hidden;
  pointer-events: none;
}

.grid-container--margin {
  @include resprop(
    "margin-bottom",
    (math.div($general-margin, 1.5), $general-margin)
  );
}

.grid-container--margin-half {
  @include resprop(
    "margin-bottom",
    (math.div($general-margin, 3), $general-margin * 0.5)
  );
}

.grid-container--margin-half-mobile {
  @include breakpoint(small only) {
    @include resprop(
      "margin-bottom",
      (math.div($general-margin, 3), $general-margin * 0.5)
    );
  }
}

.grid-container-padding--top {
  @include resprop("padding-top", ($general-margin, $general-margin * 1.5));
}

.plugin--richtext,
.plugin--margin {
  @include resprop(
    "margin-bottom",
    ($general-margin * 0.5, math.div($general-margin, 1.5))
  );
}

.plugin--margin-half {
  @include resprop(
    "margin-bottom",
    (math.div($general-margin, 3), math.div($general-margin, 3))
  );
}

.breadcrumb__link {
  display: block;
  margin-bottom: 1rem;

  &:before {
    position: relative;
    content: url("img/icons/arrow-left.svg");
    margin-right: 0.5rem;
  }
}

.grid-container--relative {
  position: relative;
}

.hidden-anchor {
  position: absolute;
  top: -10rem;
}

.no-break {
  white-space: nowrap;
  color: $black;
}

.shareholder-grid {
  @include resprop(
    "margin-bottom",
    ($general-margin * 0.5, $general-margin * 0.75)
  );
}

.shareholder__list {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 2rem;

  margin-bottom: 4rem;

  @include breakpoint(medium) {
    grid-template-columns: repeat(3, 1fr);
  }
  @include breakpoint(large) {
    grid-template-columns: repeat(5, 1fr);
  }
}

.shareholder__list-title {
  margin-top: 2rem;
}

.shareholder__logo-container {
  padding: 1rem;
  display: flex;
  align-items: center;
  align-self: center;
  justify-content: center;
  margin-bottom: 2rem;

  &:nth-child(n + 9) {
    display: none;

    @include breakpoint(medium up) {
      display: block;
    }
  }
}

.shareholder__logo-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.shareholder__logo {
  display: block;
  max-width: 100%;
  height: auto;
  margin-left: 1rem;
  margin-right: 1rem;
}

.social-wall {
  @include resprop(
    "margin-top",
    (math.div($general-margin, 1.5), $general-margin)
  );
}

.j-paginate {
  color: $primary-color !important;
  cursor: pointer !important;
  text-align: center !important;
  border-radius: $border-radius !important;
  font-size: rem-calc(18px) !important;
  padding: 0.8rem 3rem !important;
  border: 3px solid $primary-color !important;
  font-weight: $bold !important;
  width: fit-content !important;
  text-transform: none !important;
  letter-spacing: normal !important;
  font-family: $font-body !important;

  &:hover {
    color: $white !important;
    background: $primary-color !important;
  }
}

.text-center {
  text-align: center;
}

.container--white {
  background-color: $white;
  padding: $general-margin 0;
}

section.shareholder-form {
  margin-top: -155px !important;
}
