.facts {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

.facts__fact {
  position: relative;
  background: $primary-color;
  height: 15rem;

  @include breakpoint(medium down) {
    min-height: 15rem;
    height: auto;
  }

  &.facts__fact--2,
  &.facts__fact--small {
    @include resprop("flex", (1 1 100%, 1 1 50%, 2 1 23%));
  }

  &.facts__fact--4,
  &.facts__fact--medium {
    @include resprop("flex", (1 1 100%, 1 1 50%, 1 1 38%));
  }

  &.facts__fact--6,
  &.facts__fact--large {
    @include resprop("flex", (1 1 100%, 1 1 50%, 1 1 56%));
  }
  &.facts__fact--8 {
    @include resprop("flex", (1 1 100%, 1 1 50%, 1 1 68%));
  }

  &.facts__fact--red {
    background: $red;
  }

  &.facts__fact--rose {
    background: $rosa;
  }

  &.facts__fact--lightred {
    background: $lightred;
  }

  &.facts__fact--white {
    background: $white;
  }

  img {
    position: absolute;
    width: 6rem;
    bottom: 1rem;
    right: 1rem;

    &.facts__fact-image--small {
      width: 4.5rem;
    }
  }
}

.facts__fact-content {
  padding: 1.25rem 2rem;
  color: $white;

  .facts__fact--white & {
    color: $black;
  }

  p {
    @extend %body-font-small;
    max-width: 75%;

    @include breakpoint(small only) {
      .facts__fact--medium &,
      .facts__fact--large & {
        max-width: 80%;
      }
    }

    @include breakpoint(medium only) {
      font-size: 14px;

      .facts__fact--medium &,
      .facts__fact--large & {
        max-width: 80%;
      }
    }
  }
}

.facts__fact-title {
  margin-bottom: 0.5rem;
  @include resprop("font-size", (rem-calc(32), rem-calc(38), rem-calc(40)));
  max-width: 80%;

  &.facts__fact-title--small {
    @include resprop("font-size", (rem-calc(28), rem-calc(30), rem-calc(32)));
  }
}
