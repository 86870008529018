.article-filter {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  gap: $grid-gap * 0.5;
}

.article-filter__item {
  list-style: none;
}

.article-filter--borderless {
  margin-left: 0.5rem;
  border: none;
}

.article-filter__item--child {
  border: none;
  padding: 0;

  &:after {
    content: "| ";
    white-space: pre;
  }
  &:last-child {
    &:after {
      content: "";
    }
  }
}
