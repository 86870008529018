$icons: "facebook", "linkedin", "twitter", "instagram";
$icon-size: rem-calc(35px);

@mixin icons {
  &.icon {
    background-repeat: no-repeat;

    @each $icon in $icons {
      &.#{$icon} {
        background-image: url("img/icons/#{$icon}.svg");
      }
    }
  }
}
.facebook {
  &.icon-before::before {
    margin: 0 0.1rem;
  }
}

.icon-before::before {
  content: "";
  display: inline-block;
  font-size: 0;
  width: $icon-size;
  height: $icon-size;
}

.icon {
  display: inline-block;
  font-size: 0;
  background-size: cover;
  width: $icon-size;
  height: $icon-size;
  background: no-repeat 50% 50%;
}

.icon {
  background-size: contain;
  @include icons();
}

.icon--small {
  width: $icon-size;
  height: $icon-size;
}
