.footer {
  color: $white;
  background: $primary-color;
  overflow: hidden;

  .page--region & {
    display: none;
  }
}

.footer__meta {
  border-top: 2px solid $light-grayish-orange;
  background-color: $primary-color;
  color: $white;

  .footer__meta--right {
    text-align: center;
    margin-bottom: $general-margin-mobile;
    @include breakpoint(medium) {
      text-align: right;
      margin-bottom: 0;
    }
  }
  .footer__meta--left {
    text-align: center;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 1rem 0;
    @include breakpoint(medium) {
      text-align: left;
      justify-content: left;
      padding: 0;
    }
  }

  @include breakpoint(medium) {
    padding: 1.5rem 0;
  }
}
.footer__meta-content {
  height: 100%;
  align-items: center;

  @include breakpoint(small only) {
    justify-content: center;
    text-align: center;
  }
}

.footer__meta-detail {
  display: inline-block;
  padding: 0.5rem 1rem;

  @include breakpoint(medium) {
    padding: 1rem;
  }
}
.footer__social {
  display: flex;
  justify-content: center;
  padding: 2rem 2rem 0 2rem;
  svg {
    width: 1.5rem;
    height: 1.5rem;
    path {
      fill: $white !important;
    }
    g {
      stroke: $white;
      fill: transparent !important;
    }
  }
  .footer__icons {
    display: flex;
    justify-content: space-between;
    flex: 0.1 0 auto;
    &:hover {
      cursor: pointer;
    }

    a {
      padding-right: 1rem;
    }
  }

  @include breakpoint(medium) {
    display: flex;
    justify-content: flex-end;
    padding: 0;
    order: 2;
  }
}

.footer__main {
  padding: 2.5rem 0;
  @include resprop("padding", (1.5rem 0, 2.5rem 0));
}

.footer__address {
  span,
  a {
    display: block;
  }
}

.footer__content {
  text-align: center;
  margin-bottom: 3rem;

  @include breakpoint(medium) {
    text-align: left;
  }

  @include breakpoint(large) {
    margin-bottom: 0;
  }
}
