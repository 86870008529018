@font-face {
  font-family: "Graphik Web";
  src:
    url("fonts/Graphik-Bold-Web.woff2") format("woff2"),
    url("fonts/Graphik-Bold-Web.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
}
@font-face {
  font-family: "Graphik Web";
  src:
    url("fonts/Graphik-Semibold-Web.woff2") format("woff2"),
    url("fonts/Graphik-Semibold-Web.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik Web";
  src:
    url("fonts/Graphik-RegularItalic-Web.woff2") format("woff2"),
    url("fonts/Graphik-RegularItalic-Web.woff") format("woff");
  font-weight: 400;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik Web";
  src:
    url("fonts/Graphik-Regular-Web.woff2") format("woff2"),
    url("fonts/Graphik-Regular-Web.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}

$font-body: "Graphik Web", "Helvetica", "Arial", sans-serif;

$body-font-size: 1.15rem; // 18px
$body-font-size-small: 1rem; // 16px
$body-font-size-medium: rem-calc(20px);
$body-font-size-large: rem-calc(25px);
$body-font-size-tiny: rem-calc(14px);
$body-letter-spacing-small: -0.05rem;
$body-line-height-small: 1.5;
$base-size: 1rem;
$line-height: 1.4;
$bold: 700;
$semibold: 600;
$regular: 400;

%body-font-medium {
  font-size: $body-font-size-medium;
}

%body-font-small {
  font-size: $body-font-size-small;

  @include breakpoint(medium) {
    font-size: $body-font-size;
  }
}
%body-font-tiny {
  font-size: $body-font-size-tiny;
}

.text--white {
  color: $white;
}

.text--normal {
  font-weight: normal;
}

.text--medium {
  font-weight: $semibold;
}

%mix-blend-color {
  background-color: $primary-color;
  mix-blend-mode: multiply;

  .overlay--open & {
    background-color: $white;
    mix-blend-mode: normal;
  }
}

%mix-blend-color-blue {
  background-color: $azure;
  mix-blend-mode: multiply;

  .overlay--open & {
    background-color: $white;
    mix-blend-mode: normal;
  }
}

%mix-blend-font-color {
  color: $primary-color;
  mix-blend-mode: multiply;

  .overlay--open & {
    color: $white;
    mix-blend-mode: normal;
  }
}

html {
  @include resprop(
    "font-size",
    (
      $base-size,
      $base-size,
      $base-size * 1,
      $base-size * 1,
      $base-size * 1.2,
      $base-size * 1.5
    )
  );
  @include resprop(
    "line-height",
    (
      $line-height,
      $line-height,
      $line-height * 0.8,
      $line-height * 0.9,
      $line-height * 0.9
    )
  );
}

body {
  font-family: $font-body;
  font-size: $body-font-size-small;
  line-height: 1.4;
  color: $black;

  @include breakpoint(medium) {
    font-size: $body-font-size;
  }
}

h1,
.h1,
h2,
h3,
h4 {
  line-height: 1.2;
  font-weight: 700;
}

h1,
.h1 {
  @include resprop("font-size", (rem-calc(25), rem-calc(36), rem-calc(40)));
  margin-bottom: 2rem;
  @include breakpoint(small only) {
    letter-spacing: $body-letter-spacing-small;
    line-height: $body-line-height-small;

    .moodboard__title & {
      font-size: rem-calc(25);
    }
  }
}

h2 {
  @include resprop("font-size", (rem-calc(23), rem-calc(28), rem-calc(30)));
  margin-bottom: 1rem;
}

h3 {
  @include resprop("font-size", (rem-calc(23), rem-calc(25)));
  margin-bottom: 0.5rem;

  .plugin__default & {
    margin-bottom: 1rem;
  }
}

h4 {
  @include resprop("font-size", (rem-calc(20)));

  .plugin &,
  .plugin__default & {
    margin-bottom: 2rem;
  }
}

figcaption {
  @extend %body-font-tiny;
  color: $gray;
}

p,
form,
table,
ul,
ol {
  margin-bottom: 2rem;
  line-height: 1.4;
}

tr,
td {
  font-size: $body-font-size;
  font-family: $font-body;
}

small {
  font-size: 80%;
}

input,
textarea,
select,
option {
  font-family: $font-body;
  font-size: inherit;
  line-height: inherit;
  color: $black;
}
address {
  font-style: normal;
}

strong,
b {
  font-weight: $semibold;
}

sup {
  font-size: 11px;
}

.plugin__default,
.plugin {
  a {
    color: $primary-color;
  }
  ul,
  ol {
    margin-left: 1.5em;
    li {
      padding-left: 0.2em;
    }
  }

  ul,
  ol {
    li:not(:last-child) {
      margin-bottom: 0.5em;
    }
  }
}

.hyphens {
  hyphens: auto;
}

.text__copyright {
  @extend %body-font-tiny;
  color: $lightgray;

  .article__copyright & {
    color: $gray7b;
  }
}

.text__small {
  @extend %body-font-tiny;
  margin-bottom: 0.5rem;
}

.plugin__default--minus-margin {
  margin-top: -2rem;
}

.text--large {
  font-size: $body-font-size-medium;
}

.text--primary {
  color: $primary-color;
}

.text--bold {
  font-weight: $bold;
}

.text--semibold {
  font-weight: $semibold;
}

.title--background {
  span {
    color: $black;
    background-color: rgba(255, 255, 255, 0.5);
    padding: 0.5rem 1rem;
    display: inline;
    -webkit-box-decoration-break: clone;
    line-height: 1.9;

    @include breakpoint(medium) {
      line-height: 1.7;
    }
  }
}
