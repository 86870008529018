.imagetext {
  @include breakpoint(medium) {
    margin-bottom: 11rem;
  }
}

.imagetext__image-wrapper {
  position: relative;
  @include breakpoint(medium) {
    margin-left: 2rem;
  }
}

.imagetext__text {
  @include breakpoint(medium) {
    display: flex;
    align-items: center;
  }
}

.imagetext__image {
  display: block;
  outline: $outline-width solid $white;
  outline-offset: -$grid-gap;
  margin-top: 1rem;
  @include breakpoint(medium) {
    margin-top: 0;
  }
}

.imagetext__textbox {
  position: relative;
  background: $primary-color;
  padding: 1rem 3rem 0.5rem 1rem;
  max-width: 16rem;
  margin-left: auto;
  bottom: 3rem;

  @include breakpoint(medium) {
    position: absolute;
    padding: 1rem 5rem 0.5rem 2rem;
    max-width: 20rem;
    bottom: -10rem;
    right: 2rem;
  }

  @include breakpoint(large) {
    max-width: 20rem;
    bottom: -8rem;
  }

  &,
  a {
    color: $white;
    @extend %body-font-small;
  }
}
