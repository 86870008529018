.testimonial {
  //background: $white;
  padding: 4rem 0 2rem 0;
}

.testimonial__wrapper {
  display: flex;
  flex-direction: column;

  @include breakpoint(medium) {
    flex-direction: row;
  }
}

.testimonial__image-container {
  @include resprop("flex", (1 1 100%, 1 1 33%));
}

.testimonial__image {
  display: block;
  height: 100%;
  object-fit: cover;
  outline: $outline-width solid $white;
  outline-offset: -$grid-gap;
  margin: 0 auto;
}

.testimonial__content {
  @include resprop("flex", (1 1 100%, 1 1 67%));
  padding: 2rem 0 0 0;

  @include breakpoint(medium) {
    padding: 4rem 0 1rem 2rem;
  }
}

.testimonial__caption {
  @include resprop("font-size", (rem-calc(18), rem-calc(20), rem-calc(22)));
  margin-bottom: 1rem;
}

.testimonial__author {
  margin-bottom: 0.25rem;

  span {
    color: $primary-color;
  }
}

.testimonial__position {
  @extend %body-font-small;
}
