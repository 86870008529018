// Download

.downloads--grid {
  display: flex;
  flex-wrap: wrap;
}

.download__container {
  width: 100%;
  .download__link:first-child {
    border-top: 1px dashed $lightblue;
  }
}

.download__link {
  padding: 1.5rem 0.5rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  transition: background-color 200ms ease;
  border-bottom: 1px dashed $lightblue;
  flex: 1 1 50%;
  @include breakpoint(medium) {
    justify-content: normal;
    flex: 1 1 33%;
  }
  &:hover {
    background-color: $seashell;
    .download__container--dark & {
      background-color: transparent;
    }
  }
}

.download__content {
  .text-uppercase {
    text-transform: uppercase;
  }

  h3 {
    margin-bottom: 0.5rem;
    hyphens: auto;
  }
  p {
    color: $black;
    font-weight: normal;
    margin-bottom: 0;
    .download__container--dark & {
      color: $white;
    }
  }
  img {
    @include breakpoint(medium) {
      max-width: 20rem;
    }
    padding-right: 0.5rem;
  }
}

.download__icon {
  margin-top: auto;
  margin-bottom: auto;

  @include breakpoint(medium) {
    margin-left: 6rem;
  }
  .icon {
    height: 5rem;
    width: 5rem;
  }
  svg polygon {
    fill: $primary-color !important;
  }
  .downloads--grid & {
    display: none;
  }
}

.icon--download {
  color: $primary-color;
  height: 30px;
  width: auto;
}
