.messages {
  list-style: none;

  .messages__message {
    background: $lightgray;
    margin: 0 0 0.25em 0;

    @include xy-gutters(
      $gutter-position: top bottom left right,
      $gutter-type: padding
    );

    &.success {
      background-color: #c9f6dc;
    }
  }
}
