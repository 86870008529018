// Article Block & Grid

.masonry-grid,
.teasers {
  display: flex;
  width: 100%;
  //margin-bottom: $general-margin;
  flex-wrap: wrap;

  &.masonry-grid--others {
    margin-bottom: $general-margin;
    margin-top: $general-margin;
  }
}

.masonry-grid > .teaser {
  flex: 1 1 100%;
  //transition: $general-transitions;
  //background-color: $article-block-color;

  height: fit-content; // TODO only webkit support
  margin-bottom: $grid-gap;
  margin-right: $grid-gap;
  @include breakpoint(medium) {
  }

  &.teaser--shareholder {
    background: $white;
  }

  @include breakpoint(medium) {
    flex: 1 1 40%;
  }
}

.teasers > .teaser {
  flex: 1 1 100%;
  margin-bottom: $grid-gap;
  margin-right: 0;

  &.teaser--full {
    @include breakpoint(medium) {
      margin-bottom: 0;
    }
  }

  @include breakpoint(medium) {
    &.teaser--large {
      flex: 1 1 50%;
    }
    &.teaser--small {
      flex: 1 1 45%;
      margin-right: $grid-gap;
    }
  }
  @include breakpoint(large) {
    flex: 1 1 40%;
    &.teaser--large {
      flex: 1 1 60%;
    }
    &.teaser--small {
      flex: 1 1 35%;
    }
  }
  @include breakpoint(large) {
    &:first-of-type {
      margin-right: $grid-gap;
    }
  }
}

.teaser--event {
  display: block;
  height: 100%;
  background-color: $white;
}

.teaser--newsticker {
  background-color: $teaser-background-color;
}

.teaser__wrapper {
  background: $teaser-background-color;
  padding: $teaser-padding;
  height: 100%;
  z-index: 1000;

  .teaser--event & {
    background: none;
  }

  //outline: $outline-width solid $lightgray;
  //outline-offset: -$grid-gap-small;

  &.teaser__wrapper--claws {
    position: relative;
    padding: 2rem;
    z-index: auto;
  }

  &.teaser__wrapper--no-background {
    background: none;
  }

  .teaser--newsticker & {
    padding: $teaser-padding * 0.5;
  }

  //margin-top: -2.65rem;

  .teasers & {
    height: 100%;
  }

  .teaser--full & {
    margin-top: 0;
    display: flex;
    flex-direction: column;
  }

  .teaser--large & {
    @include breakpoint(large) {
      margin-top: 0;
      margin-right: -2.65rem;
      flex: 1 1 50%;
    }
  }

  .teaser--noimage & {
    margin-top: 0;
    margin-right: 0;
  }
}

.teaser__claws {
  position: relative;
  height: 100%;
}

.teaser__content {
  hyphens: auto;
  //padding: $grid-gap-small $grid-gap-small 0.5rem $grid-gap-small;
  position: relative;
  z-index: 1000;
  height: auto;

  .teaser--newsticker & {
    background-color: $white;
    padding: $teaser-padding * 0.5;
  }

  &.teaser__content--full {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-end;

    height: 100%;
    //padding: $grid-gap-small $grid-gap-small;

    color: $white;
    //text-shadow: 0 0 5px transparentize($black, 0.25);

    .teaser__wrapper--claws & {
      padding: 2rem 2rem 2rem 2.5rem;
      z-index: auto;

      @include breakpoint(medium) {
        padding: 2rem 2rem 2rem 4rem;

        .teaser--small & {
          padding: 2rem;
        }
      }
    }
  }

  .teaser--large & {
    @include breakpoint(large) {
      padding-right: 2.65rem;
    }
  }

  .teaser__title {
    hyphens: manual;
    margin-bottom: 0.75rem;
    color: $black;
  }

  .teaser--shareholder & {
    color: $black;
  }
}

.teaser__description {
  @extend %body-font-small;
  color: $black;
  margin-bottom: 0;

  &.no_excerpt {
    display: none;
  }
}

.teaser__button {
  @extend %body-font-small;
  color: $black;
  text-align: right;
  margin: $grid-gap;
  font-weight: $regular;

  &.teaser__button--multiply {
    position: relative;
    color: $white;
    background: none;
    border: none;
    margin: 0;
    text-shadow: none;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: $primary-color;
      mix-blend-mode: multiply;
    }

    span {
      position: relative;
      z-index: 1000;
      display: block;
    }
  }
}

.teaser__category {
  position: absolute;
  bottom: $teaser-padding * 0.8;
  left: $teaser-padding;
  background: rgba(255, 255, 255, 0.8);
  font-weight: 700;
  @extend %body-font-small;
  color: $primary-color;
  padding: 0.25rem 0.4rem;
  width: fit-content;
  margin-left: auto;
}

.teaser__pubdate {
  font-weight: $regular;
  color: $primary-color;
  margin-bottom: 0.5rem;

  .teaser--noimage & {
    margin-bottom: 1.5rem;
  }

  .teaser--red &,
  .teaser--blue & {
    color: $white;
  }

  .teaser--large & {
    @include breakpoint(large) {
      margin-bottom: 1.5rem;
    }
  }
}

.teaser-author {
  display: flex;
  margin-bottom: 1rem;
}

.teaser-author__image-container {
  margin-right: 0.5rem;
}

.teaser-author__name {
  margin-bottom: 0;
  @extend %body-font-small;
  font-weight: 700;
}

.teaser-author__position {
  margin-bottom: 0.25rem;
  @extend %body-font-small;
}

.teaser-shareholder__content {
  margin-bottom: 2rem;
}

.teaser-shareholder {
  display: flex;
  @extend %body-font-small;
}

.teaser-shareholder__image-container {
  min-width: rem-calc(100px);
  min-height: rem-calc(100px);
}

.teaser-shareholder__image {
  display: block;
  max-height: 100px;
}

.teaser-shareholder__author {
  padding-left: 1rem;
}

.teaser-shareholder__author-name {
  font-weight: 700;
  margin-bottom: 0.25rem;
}

.teaser-shareholder__author-position {
  color: $gray7b;
}

.teaser__wrapper--border {
  padding: $grid-gap;

  .teaser--shareholder & {
    //outline: $outline-width solid $lightgray;
    //outline-offset: -$grid-gap;
  }
}

.teaser__icons {
  z-index: 100;

  &.teaser__icons--with-img {
    position: absolute;
    margin: 0.25rem;

    .teaser__icon {
      line-height: 0;
      padding: 0.25rem;
      display: inline-block;
      background-color: $white;
    }
  }
}

div[data-object-fit] {
  height: 100%;
}

.image__logo-container {
  height: 125px;
}

.teaser__image-wrapper {
  position: relative;

  &.teaser__image-wrapper--icon {
    padding: $grid-gap;
  }

  &.teaser__image-wrapper--no-background-color {
    &:before {
      background-color: transparent !important;
    }
  }

  &.teaser__image-wrapper--full {
    position: absolute;
    height: 100%;
    width: 100%;

    &:before {
      content: "";
      background-color: transparentize($black, 0.7);
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    img {
      display: block;
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  .teaser--large & {
    @include breakpoint(large) {
      flex: 1 1 50%;
    }
  }

  .teaser__image {
    display: block;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  .teaser--shareholder & {
    padding: $grid-gap $grid-gap 0 $grid-gap;
    display: flex;
    align-content: center;
    justify-content: center;
  }
}

.teaser__eyecatcher {
  position: absolute;
  background: $primary-color;
  height: 5rem;
  width: 6.5rem;
  padding: 0.75rem;
  top: 0;
  right: 0;
  clip-path: polygon(100% 0, 0 0, 100% 100%);

  span {
    display: block;
    color: $white;
    font-size: 1rem;
    width: 100%;
    text-align: center;
    transform: rotate(37.5deg) translateX(15px);
  }
}

.teaser__image--logo.lazyloaded {
  object-fit: none;

  &.lazyload {
    object-fit: contain;
  }
}

//For Modern Browser with CSS Grid Support
@supports (grid-area: auto) {
  .masonry-grid {
    @include breakpoint(medium) {
      display: grid;
      grid-gap: $grid-gap;
      grid-template-columns: repeat(auto-fill, minmax(rem-calc(300), 1fr));
      grid-auto-rows: $grid-gap;
    }

    .teaser {
      background-color: $white;
      margin-right: 0;

      &.teaser--large {
        @include breakpoint(large) {
          grid-column-end: 3;
          grid-column-start: 1;
        }
      }

      &.teaser--newsticker {
        grid-row-start: 1;
        @include breakpoint(large) {
          grid-column-start: 3;
          grid-row: 1 / 5;
        }
      }
    }

    .teaser--transparent {
      background-color: transparent;
    }

    &.masonry-grid--agenda-teaser {
      .teaser--large {
        @include breakpoint(large) {
          grid-column-end: 4;
          grid-column-start: 2;
        }
      }
    }

    .teaser__agenda {
      grid-column-start: 1;
      grid-row-start: 1;

      @include breakpoint(medium) {
        grid-column-start: 2;
      }

      @include breakpoint(large) {
        grid-column-start: 3;
      }
    }
  }
}

// Detail Page

.article__back-link {
  //font: $caption-font;
  font-weight: bold;
  text-decoration: underline;
  margin-bottom: 2rem;
}

.article__image__caption {
  p {
    //@extend .image-caption;
    margin-top: 0.25rem;
  }

  margin-bottom: $general-margin;
}

.article__pubdate {
  //font: $caption-font;
  p {
    margin-bottom: 0.25rem;
  }
}

// Pagination

.pagination {
  text-align: center;
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;

  .previous,
  .next {
    align-self: center;
    display: flex;

    svg {
      width: 20px;
      height: 20px;
      fill: $black;
    }
  }

  .previous {
    transition: all 0.2s ease-in-out;
    margin-right: 0.5rem;

    &:hover {
      transform: translateX(-20%);
      padding-right: 0.5rem;
      margin-right: 0;
    }
  }

  .next {
    margin-left: 0.5rem;
    transition: all 0.2s ease-in-out;

    &:hover {
      transform: translateX(20%);
      padding-left: 0.5rem;
      margin-left: 0;
    }
  }

  .pagination__list {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-self: center;

    .pagination__item {
      padding: 0 0.8rem;
      //font: $caption-font;
      display: flex;
      justify-content: center;
      text-align: center;
      align-content: center;
      font-weight: $bold;

      &.pagination__item--active {
        color: $primary-color;
      }

      a {
        align-self: center;
      }
    }
  }
}

.newsticker__header {
  padding: $grid-gap;

  h2 {
    margin-bottom: 0;
  }
}

.teaser__news-date {
  color: $primary-color;
  font-weight: $regular;
}

.teaser__news-category {
  color: $primary-color;
  margin-bottom: 1rem;
  font-style: italic;
}

.teaser__news-logo {
  width: 100%;

  img {
    display: block;
    max-height: 3rem;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
  }
}

.teaser__news-title {
  p {
    margin-bottom: 1rem;
  }
}

.newsticker__button {
  width: fit-content;
  margin: 0 auto;
  padding: $grid-gap 0;
}

.teaser__external-icon {
  display: none;
  position: absolute;
  background-image: url("img/icons/external-icon.svg");
  background-size: cover;
  top: $grid-gap * 0.5;
  right: $grid-gap * 0.5;
  width: 1rem;
  height: 1rem;
}

.css-grid-container {
  display: grid;
  grid-template-columns: repeat(1, 1fr);

  @include breakpoint(medium) {
    grid-template-columns: repeat(2, 1fr);
  }
  @include breakpoint(large) {
    grid-template-columns: repeat(3, 1fr);
  }
  grid-gap: 1rem;
}

.css-grid-container--textboxes {
  grid-template-columns: repeat(1, 1fr);

  @include breakpoint(medium) {
    grid-template-columns: repeat(2, 1fr);
  }
  @include breakpoint(large) {
    grid-template-columns: repeat(2, 1fr);
  }

  .box__content {
    height: 100%;
  }
}

.css-grid-container > .teaser {
  height: 100%;

  &.teaser--shareholder {
    background: $white;
  }

  @include breakpoint(medium) {
    flex: 1 1 40%;
  }
}

.teaser--azure {
  background-color: $azure;
}

.teaser--small {
  height: 100%;

  .article & {
    height: auto;
  }
}

.teaser__item {
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;

  .teaser--full & {
    @include breakpoint(small only) {
      min-height: 32rem;
    }
  }

  .teaser--small & {
    min-height: auto;
  }

  .teasers & {
    height: 100%;
  }

  .teaser--large & {
    @include breakpoint(large) {
      flex-direction: row-reverse;
    }
  }

  .teaser--red & {
    background-color: $red;
    color: $white;
  }

  .teaser--blue & {
    background-color: $blue;
    color: white;
  }

  .teaser--beige & {
    background-color: $beige;
  }

  .teaser--lightblue & {
    background-color: $lightblue;
  }

  //.teaser--kultur & {
  //  background-color: $event-color;
  //}

  .teaser--diverses & {
    background-color: $beige;
  }
}
