@use "sass:math";

.moodboard {
  @include resprop("height", (80vh, 97vh, 97vh, 97vh, 90vh));
  position: relative;

  .page--region &,
  .page--no-moodboard & {
    display: none;
  }
}

.moodboard__slider {
  position: relative;
  overflow: hidden;
  height: 100%;
  z-index: 100;
}

.moodboard__mask {
  position: absolute;
  display: flex;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding-bottom: 3rem;
  //transition: transform 0.9s ease-in-out !important;

  @include breakpoint(medium) {
    padding-bottom: 8vh;
  }
}

.moodboard__slide {
  color: white;
  position: relative;
  flex: 1 0 100vw;
}

.moodboard__image-cover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  overflow: hidden;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    //background-color: rgba(239, 51, 64, 0.9);
    background-color: rgba(229, 85, 79, 0.9);
    mix-blend-mode: multiply;
    pointer-events: none;
    opacity: 0;
    z-index: 1000;

    .overlay--open & {
      opacity: 1;
    }
  }
}

.moodboard__image-container {
  height: 100%;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.4);
    z-index: 1;
    opacity: 0;

    .overlay--open & {
      opacity: 1;
    }
  }
}

.moodboard__image {
  position: absolute;
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.moodboard__container {
  position: relative;
  height: 100%;
  width: 100%;
}

.moodboard__cover {
  position: relative;
  width: 100%;
  height: 100%;
}

.moodboard__info-button {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: rem-calc(40px);
  height: rem-calc(40px);
  left: 0;
  bottom: 3rem;
  cursor: pointer;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.4);
    z-index: 1;
    opacity: 1;
  }

  @include breakpoint(medium) {
    bottom: 8vh;
  }

  svg {
    padding: 0.25rem;
    position: relative;
    z-index: 100;
  }

  //&:after {
  //  content: url("img/icons/info_button.svg");
  //  position: relative;
  //  z-index: 1001;
  //}
  //
  &:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: $primary-color;
    mix-blend-mode: multiply;
    z-index: 2;
  }
}

.moodboard__inner-wrapper {
  position: relative;
  @include resprop("height", (58vh, 60vh, 65vh, 70vh, 70vh, 70vh));
  margin: 2rem 0 10rem 0;

  @include breakpoint(medium) {
    margin: 8vh 0 10rem 0;
  }
}

.moodboard__logo-container {
  position: absolute;
  top: 0;
  left: math.div($claw-width, $claw-thickness-mobile);
  width: 50%;

  @include breakpoint(medium) {
    width: 40%;
  }

  @include breakpoint(large) {
    width: fit-content;
    left: $claw-width + 2rem;
  }
}

.moodboard__logo {
  @extend %mix-blend-font-color;
  @extend .h1;

  @include breakpoint(small only) {
    transform: translateY(-0.3rem);
    font-size: rem-calc(20px);
    line-height: normal;
  }

  @include breakpoint(medium) {
    transform: translateY(-0.5rem);
  }

  .overlay--open {
    color: $white;
  }
}

.moodboard__menu-container {
  position: absolute;
  top: -0.1rem;
  right: math.div($claw-width, $claw-thickness-mobile);
  height: 5rem;
  width: fit-content;

  @include breakpoint(large) {
    right: $claw-width + 2.25rem;
  }
}

.moodboard__link-container {
  display: none;
  padding: 4rem 0 5rem math.div($claw-width, $claw-thickness-mobile) - 0.3rem;

  @include breakpoint(medium) {
    padding: 6rem 0 5rem math.div($claw-width, $claw-thickness-mobile) - 0.3rem;
  }

  @include breakpoint(large) {
    padding: 8vh 0 5rem $claw-width + 2rem;
  }

  .overlay--menu & {
    display: block;
  }
  .overlay--sticky & {
    position: absolute;
    display: block;
    pointer-events: none;
  }
}

.moodboard__menu {
  pointer-events: all;
  ul li {
    margin: 0;
    padding: 0;
    list-style: none;
  }
}

.moodboard__link {
  display: flex;
  align-items: center;
  margin-bottom: 0;
  @include resprop(
    "font-size",
    (rem-calc(22), rem-calc(30), rem-calc(32), rem-calc(38))
  );

  @include breakpoint(medium) {
    margin-bottom: $general-margin * 0.25;
  }

  svg {
    display: block;

    @include breakpoint(small only) {
      width: 2rem;
    }
  }
}

.moodboard__claw {
  position: absolute;
  height: 100%;
  width: math.div($claw-thickness, $claw-thickness-mobile);
  pointer-events: none;
  @extend %mix-blend-color;

  &.moodboard__claw--blue {
    @extend %mix-blend-color-blue;
  }

  @include breakpoint(large) {
    width: $claw-thickness;
  }

  &:before,
  &:after {
    content: "";
    position: absolute;
    width: $claw-width * 0.5;
    height: math.div($claw-thickness, $claw-thickness-mobile);
    @extend %mix-blend-color;

    @include breakpoint(large) {
      height: $claw-thickness;
      width: $claw-width;
    }
  }

  &.moodboard__claw--blue {
    &:before,
    &:after {
      @extend %mix-blend-color-blue;
    }
  }

  &.moodboard__claw--white {
    mix-blend-mode: normal;
    background-color: $white;
    &:before,
    &:after {
      background-color: $white;
      mix-blend-mode: normal;
    }
  }

  &.moodboard__claw--cover {
    mix-blend-mode: normal;

    &:before,
    &:after {
      mix-blend-mode: normal;
    }
  }

  &.moodboard__claw--small {
    &:before,
    &:after {
      @include breakpoint(large) {
        width: $claw-width * 0.5;
      }
    }
  }

  &:before {
    top: 0;
  }

  &:after {
    bottom: 0;
  }

  &.moodboard__claw--left {
    top: 0;
    left: 0;

    &:before,
    &:after {
      left: math.div($claw-thickness, $claw-thickness-mobile);

      @include breakpoint(large) {
        left: $claw-thickness;
      }
    }
  }
  &.moodboard__claw--right {
    top: 0;
    right: 0;

    &:before,
    &:after {
      right: math.div($claw-thickness, $claw-thickness-mobile);

      @include breakpoint(large) {
        right: $claw-thickness;
      }
    }
  }
}

.moodboard__content {
  @include breakpoint(large) {
    font-size: $body-font-size-medium;
  }
}

.moodboard__container-wrapper {
  position: relative;
  height: 70vh;
  width: 100%;
  //max-width: 75rem;
}

.moodboard__content-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  @include resprop("height", (80vh, 90vh, 90vh));
  padding: math.div($claw-width, $claw-thickness-mobile);
  z-index: 1020;
  color: $white;
  opacity: 0;

  @include breakpoint(medium) {
    padding: math.div($claw-width, $claw-thickness-mobile);
  }

  @include breakpoint(large) {
    padding: $claw-width + $claw-thickness + 1.5rem;
  }

  .overlay--info & {
    opacity: 1;
  }
}

.moodboard__content-container {
}

div[data-animate] {
  animation: scale 1s ease-in 0.2s alternate forwards;
}

.moodboard__title {
  padding-top: $outline-width;
  h1 {
    background-color: $white;
    background-repeat: no-repeat;
    text-shadow: none;
    box-decoration-break: clone;
    display: inline;
    padding: 0.4rem 0.6rem;

    @include resprop(
      "font-size",
      (rem-calc(22), rem-calc(28), rem-calc(28), rem-calc(40))
    );
  }
}

@keyframes scale {
  0% {
    transform: scale(1.05);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.js-scale {
  animation: scale 1.4s;
}

.gallery__content {
  position: absolute;
  z-index: 100;
  background: none;
  width: 100%;
  color: $white;
  padding: 1rem;
  padding-top: 19em;
  text-align: center;

  @include breakpoint(medium) {
    padding-top: 28em;
  }

  // height: 20rem;

  & > .button {
    a {
      text-align: center;
      letter-spacing: 2px;
      font-weight: bold;
    }
  }

  @include breakpoint(medium) {
    width: 100%;
    height: 100%;
  }
}

.slide__title {
  font-weight: bold;
  margin-bottom: 0.5rem;
  width: 100%;
  font-size: 1.875rem;
  // line-height: 1.2;

  @include breakpoint(medium) {
    font-size: 3.75rem;
  }
  @include breakpoint(large) {
    font-size: 3.75rem;
  }
}

.slide__description {
  font-size: 1rem;
  line-height: 1.3;
  margin-bottom: 0.5rem;

  @include breakpoint(medium) {
    font-size: 1.2rem;
    margin-bottom: 1rem;
  }
  @include breakpoint(large) {
    font-size: 1.8rem;
    margin-bottom: 1.5rem;
  }
}

.gallery__thumbs {
  display: none;

  @include breakpoint(medium) {
    display: block;
  }

  overflow: visible;
  position: absolute;
  bottom: 0.5rem;
  width: 50%;

  // necessary because slides have max width of 75rem (grid)
  max-width: 37.5rem;
  right: 50%;

  text-align: center;

  [data-thumb] {
    display: inline-block;
    width: rem-calc(12);
    height: rem-calc(12);
    opacity: 1;
    background-color: transparent;
    border: 2px solid white;
    border-radius: $border-radius;
    margin: 0 0.5rem;
  }

  [data-current] {
    background-color: white;
  }
}

$control-size: 3rem;

.gallery__playpause {
  position: absolute;
  display: none; // currently disabled feature
  bottom: 0;
  right: 0;
  height: $control-size;
  width: $control-size;
  border: 0.5rem solid $white;
  border-radius: $border-radius 0 0 0;
  background: $white center/contain no-repeat;
  background-image: url("img/icons/play.svg");
  [data-playing="true"] & {
    background-image: url("img/icons/pause.svg");
  }

  &:hover {
    border-color: $black;
    background-color: $black;
    background-image: url("img/icons/play-white.svg");
    [data-playing="true"] & {
      background-image: url("img/icons/pause-white.svg");
    }
  }
}

.gallery__go {
  position: absolute;
  z-index: 9; // above links
  display: block;
  top: 0;
  height: 25vh;
  width: 100%;

  outline: none;

  display: flex;
  align-items: center;

  @include breakpoint(medium) {
    height: 100%;

    // do not overlap the sharing buttons
    &[data-go="1"] {
      height: calc(50% + #{$control-size * 0.5});
      align-items: flex-end;
    }
  }

  &::before {
    content: "";
    display: block;
    width: rem-calc(41px);
    height: rem-calc(31px);
    background: $white 50% 65% / contain no-repeat;
    border: 0.2rem solid $white;
    border-radius: $border-radius $border-radius 0 0;

    @include breakpoint(medium up) {
      height: $control-size;
      width: $control-size;
      background-size: 50%;
      background-position: center;
    }
  }

  &:hover::before {
    background-color: $black;
    border-color: $black;
  }

  &[data-go="-1"] {
    left: 0;

    &::before {
      background-image: url("img/icons/expand-left.svg");
    }

    &:hover::before {
      background-image: url("img/icons/expand-white-left.svg");
    }
  }

  &[data-go="1"] {
    right: 0;
    justify-content: flex-end;

    &::before {
      background-image: url("img/icons/expand-right.svg");
    }

    &:hover::before {
      background-image: url("img/icons/expand-white-right.svg");
    }
  }
}

.gallery__playpause,
.gallery__go,
.gallery__thumbs {
  visibility: hidden;
  [data-hide-controls="false"] & {
    visibility: visible;
  }
}

.moodboard__copyright {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 100;

  padding: $grid-gap * 0.5;
}

.moodboard__thumbs {
  $thumb-length: rem-calc(22px);
  $thumb-border: rem-calc(2px);

  position: absolute;
  left: 0;
  bottom: 0.5rem;

  width: 100%;
  text-align: center;
  z-index: 1000;

  @include breakpoint(medium) {
    bottom: 0;
  }

  a[data-thumb] {
    position: relative;

    display: inline-block;
    width: $thumb-length * 0.5;
    height: $thumb-length * 0.5;

    @include breakpoint(medium) {
      width: $thumb-length;
      height: $thumb-length;
    }

    margin: 0 0.375rem;

    border: $thumb-border solid $white;
    border-radius: 50%;

    &::before {
      $dot-length: $thumb-length - ($thumb-border * 6);
      content: "";

      position: absolute;
      top: $thumb-border * 2;
      left: $thumb-border * 2;

      display: block;
      width: $dot-length;
      height: $dot-length;

      background-color: $red;
      border-radius: 50%;
      opacity: 0.7;

      transform: scale(0.8);
      @include breakpoint(medium) {
        transform: scale(1.2);
      }
      transition: transform 0.3s ease;
    }

    &:hover {
      &::before {
        transform: scale(1.5);
        transition-duration: 0.2s;

        @include breakpoint(medium) {
          transform: scale(2);
        }
      }
    }

    &[data-current] {
      &::before {
        transform: scale(1.5);
        opacity: 1;

        @include breakpoint(medium) {
          transform: scale(2);
        }
      }
    }
  }

  [data-hide-controls="true"] & {
    display: none;
  }
}

.moodboard__arrow {
  display: none;
  width: rem-calc(22px);
  height: rem-calc(40px);

  position: absolute;
  top: 50%;

  svg {
    overflow: visible;
    polyline {
      filter: drop-shadow(0 0 3px transparentize($black, 0.4));
    }
  }

  &[data-go="-1"] {
    left: calc(50% - #{$global-width * 0.5});
  }

  &[data-go="1"] {
    right: calc(50% - #{$global-width * 0.5});

    svg {
      transform: rotate(180deg);
    }
  }

  @include breakpoint($global-width + 1.5rem) {
    display: block;
  }

  [data-hide-controls="true"] & {
    display: none;
  }
}
